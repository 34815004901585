const pengguna = [
    {
        name: 'Data Pengguna',
        link: {name: 'ListUserView'},
        role: ['superadmin'],
    },
    // {
    //     name: 'Group Pengguna',
    //     link: {name: ''},
    //     role: ['superadmin'],
    // },
    {
        name: 'Log Admin',
        link: {name: ''},
        role: ['superadmin'],
    },
    {
        name: 'Monitoring',
        link: {name: ''},
        role: ['superadmin'],
    },
]

const settings = [
    {
        name: 'Setting Web',
        link: {name: 'ViewSettingWeb'},
        role: ['superadmin']
    },
    {
        name: 'Setting Pasaran',
        link: {name: 'ViewSettingPasaran'},
        role: ['superadmin']
    },
    {
        name: 'Setting Games',
        link: {name: 'ViewSettingGame'},
        role: ['superadmin']
    },
    {
        name: 'Setting Periode',
        link: {name: 'ViewSettingPeriode'},
        role: ['superadmin']
    },
    {
        name: 'Setting Promo',
        link: {name: 'ViewSettingPromo'},
        role: ['superadmin']
    },
    // {
    //     name: 'Setting Spin',
    //     link: {name: 'ViewSettingSpin'},
    //     role: ['superadmin']
    // },
    {
        name: 'Setting IP',
        link: {name: 'ViewSettingIp'},
        role: ['superadmin']
    },
    {
        name: 'Setting Slider',
        link: {name: 'ViewSettingSlider'},
        role: ['superadmin']
    },
]

const pelanggan = [
    {
        name: 'Data Pelanggan',
        link: {name: 'ViewDataPelanggan'},
        role: ['superadmin']
    },
    {
        name: 'Data Pelanggan Register',
        link: {name: 'ViewDataPelangganRegister'},
        role: ['superadmin']
    },
    {
        name: 'Data Pelanggan Pending',
        link: {name: 'ViewDataPelangganPending'},
        role: ['superadmin']
    },
    {
        name: 'Pesan',
        link: {name: 'ViewDataPesan'},
        role: ['superadmin']
    },
    {
        name: 'Player Online',
        link: {name: 'ViewDataPlayerOnline'},
        role: ['superadmin']
    },
    {
        name: 'Total Register',
        link: {name: 'ViewDataTotalRegister'},
        role: ['superadmin']
    },
]

const bank = [
    {
        name: 'Bank',
        link: {name: 'ViewBank'},
        role: ['superadmin']
    },
    {
        name: 'Rekening',
        link: {name: 'ViewRekening'},
        role: ['superadmin']
    },
    {
        name: 'Rekap Bank',
        link: {name: 'ViewRekapBank'},
        role: ['superadmin']
    },
    {
        name: 'Rekap WD',
        link: {name: 'ViewRekapWd'},
        role: ['superadmin']
    },
    {
        name: 'Rekap Depo',
        link: {name: 'ViewRekapDepo'},
        role: ['superadmin']
    },
]

const laporan = [
    {
        name: 'Rekap Pasaran',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Pelanggan',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Bonus',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Transfer',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Profit',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Hasil Analisa',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Spin',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Poin',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Kupon',
        link: {name: ''},
        role: ['superadmin']
    },
]

const laporanEgames = [
    {
        name: 'Rekap DD 48 Ball Harian',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap DD 24 Ball Harian',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap Joker',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap WM Casino',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap LG Casino',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap AWC Casino',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap PG',
        link: {name: ''},
        role: ['superadmin']
    },
    {
        name: 'Rekap HB',
        link: {name: ''},
        role: ['superadmin']
    },
]

const transaction = [
    {
        name: 'Withdraw',
        link: {name: 'ViewWithdrawData'},
        role: ['superadmin']
    },
    {
        name: 'Deposit',
        link: {name: 'ViewDepositData'},
        role: ['superadmin']
    },
]

export {
    pengguna,
    settings,
    pelanggan,
    bank,
    laporan,
    laporanEgames,
    transaction
}