import localForage from 'localforage';
import {
    transaction,
    pengguna,
    settings,
    pelanggan,
    bank,
    laporan,
    laporanEgames,
} from './sidebar/children'

const roleFilter = async (routes) => {
    const temp = await localForage.getItem("una4d")
    let myrole = null
    if(temp){
        myrole = temp.auth.user.role_name
    }
    return routes.filter((row) => {
        return (row.role.length == 0 || row.role.some(x => x == myrole))
    })
}
const sidebar = async () => { 
return [
        {
            name: 'Dashboard',
            link: {name: 'Dashboard'},
            icon: 'Dashboard',
            role: [],
            children: [],
        },
        {
            name: 'Settings',
            link: {},
            icon: 'setting',
            role: ['superadmin'],
            children: await roleFilter(settings)
        },
        {
            name: 'Transaksi',
            link: {},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(transaction)
        },
        {
            name: 'Ebet',
            link: {name: ''},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: []
        },
        {
            name: 'Pelanggan',
            link: {name: ''},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(pelanggan)
        },
        {
            name: 'Bank',
            link: {name: ''},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(bank)
        },
        {
            name: 'Laporan',
            link: {name: ''},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(laporan)
        },
        {
            name: 'Laporan E-Games',
            link: {name: ''},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(laporanEgames)
        },
        {
            name: 'Pengguna',
            link: {},
            icon: 'data-pengguna',
            role: ['superadmin'],
            children: await roleFilter(pengguna)
        },
    ]
}

export default sidebar