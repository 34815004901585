<template>
    <div id="Sidebar" class="y-purple-bg" :class="!collapse ? '' : 'y-hide-sidebar'">
        <div class="y-sidebarContent">
            <div class="y-sidebarHeader r-center-flex" :class="!collapse ? '' : 'y-hide-sidebarHeader'">
                <div class="y-initialName">{{inisial}}</div>
                    <div class="y-userDetail">
                        <p class="y-name y-ellipsis">{{user.name}}</p>
                        <p class="y-role y-gray-text y-ellipsis">{{user.role_name}}</p>
                    </div>
                    <div class="r-humburger" @click="setCollapse()">
                        <img :src="require('@/assets/icons/sidebar/menu.svg')">
                    </div>
            </div>
                <div class="y-sidebarItems">
                <div class="y-sidebarItem" :class="accordion == sc.name ? 'r-show-accordion y-accordion-isActive' : 'r-hide-accordion'"  @click.prevent="goto(sc.link, sc.name, sc.children.length > 0)" v-for="(sc, index) in availableComponent" :key="`sidebar-${index}`">
                        <div class="y-sidebarButton r-center-flex" :class="activeTab == sc.name ? 'y-siebarItem-isActive': ''">
                            <img class="iconSidebar" :src="require(`@/assets/icons/sidebar/${activeTab == sc.name ? 'active': 'inactive'}/${sc.icon}.svg`)">
                            <p class="y-ellipsis" style="max-width: 174px">{{sc.name}}</p>
                            <img :src="require(`@/assets/icons/others/accordion.svg`)" style="margin-left: auto;" :style="accordion == sc.name ? '' : 'transform: rotate(180deg)'" v-if="sc.children.length > 0">
                        </div>
                        <div class="y-sidebarButton r-center-flex" v-for="(child, index) in sc.children" :key="`sidebar-child-${index}`" @click.prevent="goto(child.link, child.name, false)">
                            <div class="r-bullet-separator"></div>
                            <p class="y-ellipsis" style="max-width: 174px">{{child.name}}</p>
                        </div>
                </div>
            </div>
            <div class="y-sidebarFooter r-center-flex">
                <div class="y-sidebarItem">
                        <div class="y-sidebarButton r-center-flex" @click.prevent="$router.push({name: 'logoutComponent'})">
                            <img :src="require('@/assets/icons/sidebar/logout.svg')">
                            <p>Logout</p>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidebar from '@/common/sidebar'
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            availableComponent: 'sidebar/getSidebar',
            activeTab: 'getActiveTab',
            collapse: 'getCollapse',
            user: 'auth/getUser',
            role: 'auth/getRole',
        }),
        inisial() {
            if (this.user.name.length < 1) {
                return "NN";
            } else {
                var temp = this.user.name.split(" ");
                var inisial = "";
                for (var i = 0; i < temp.length; i++){
                    if(i > 1){
                        break
                    }
                    inisial += temp[i][0];
                }
                return inisial;
            }
        },
    },
    async mounted() {
        this.getMyIdentity();
        this.setSideBar();
    },
    data: () => ({
        accordion: null,
        request:{
            page: 1,
            show: 16,
            sortBy: "begin_date",
            sorting: "DESC",
            search: null
        },
    }),
    methods:{
        setCollapse(){
            this.$store.commit('SET_COLLAPSE')
        },
        async setSideBar(){
            const sd = await sidebar()
            const filterSd = sd.filter(row => {
                return (row.role.length == 0 || row.role.some(role => role == this.role)) 
            })
            this.$store.dispatch("sidebar/setSideBar", filterSd)
        },
        goto(link,name, isParent = false){
            if(isParent){
                if(this.accordion === name){
                    this.accordion = null
                }else{
                    this.accordion = name
                }
            }else{
                this.accordion = name
                this.$store.commit('SET_ACTIVETAB', name)
                this.$store.commit('RESET_COLLAPSE')
                this.$router.push(link)
            }
        },
        async getMyIdentity() {
            if (this.$store.getters["auth/getAppkey"] != localStorage.getItem("appkey")) {
                this.$router.push({ name: "logoutComponent" });
            }
        },
    }
}
</script>
<style lang="scss">
.y-initialName {
    text-transform: uppercase;
    background: linear-gradient(225deg, #CDD2FD 3.26%, #6979F8 100%);
    border-radius: 5.625px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    height: 36px;
    min-width: 36px;
    max-width: 36px;
    display: flex;
    align-items: center;
    margin-right: 12px;
}
.r-humburger{
    opacity: 0;
}
.r-show-accordion{
    max-height: auto;
}
.r-hide-accordion{
    max-height: 40px;
}
.y-userDetail {
    cursor: default;
    .y-name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 4px;
        max-width: 122px;
    }
    .y-role {
        max-width: 122px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
    }
}

#Sidebar{
    color: white;
    position: fixed;
    bottom: 0px;
    top: 0px;
    left: 0px;
    overflow-x: hidden;
    transition: all 0.2s ease;
    .y-sidebarContent{
        position: relative;
        height: 100%;
        .y-sidebarHeader{
            min-height: 78px;
            padding: 0px 20px;
            border-bottom: 1px solid #085d60;
            transition: all 0.2s ease;
        }
        .y-siebarItem-isActive{
            background-color: #434896 !important;
        }
        .y-accordion-isActive{
            background-color: #434896 !important;
        }
        .y-sidebarItems{
            position: relative;
            border-bottom: 1px solid #565ECB;
            max-height: calc(100vh - 125px);
            overflow-y: scroll;
            overflow-x: hidden;
            margin-right: -16px;
            min-width: 230px;
        }
        .y-sidebarFooter{
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            border-top: 1px solid #434896;
            .y-sidebarItem{
                width: 100%;
            }
        }
        .y-sidebarItem{
            cursor: pointer;
            overflow: hidden;
            min-width: 230px;
            .y-sidebarButton{
                min-height: 40px;
                max-height: 40px;
                padding: 0px 10px;
                .iconSidebar{
                    width: 20px;
                }
                img{
                    margin-right: 10px;
                }
            }
            &:hover{
                background-color: #3f4491 !important;
            }
        }
    }
}
</style>